<template>
  <div>
    <div class="mt-1">
      <validation-observer ref="addForm">
        <b-form @submit.prevent>
          <b-card class="block-text" title="">
            <div class="border-bottom pb-1 mb-2">
              <span class="h4">DETAILS DU MANIFESTE</span>
            </div>

            <div class="row">
              <b-form-group class="col pr-0">
                  <label for="num_manifeste">Numéro manifeste</label>
                  <b-form-input
                    id="num_manifeste"
                    type="text"
                    placeholder="DE78596"
                    v-model="addManifesteForm.num_manifeste"/>
              </b-form-group>
              <b-form-group class="col pr-0">
                  <label for="association">Association</label>
                  <b-form-select
                    id="association"
                    v-model="addManifesteForm.association"
                    :options="associationsList"
                    value-field="id"
                    text-field="name"
                    @change="checkAssociation"
                  />
              </b-form-group>

              <!-- <b-form-group class="col pr-0">
                <validation-provider
                  #default="{ errors }"
                  name="associes/acheteur"
                  rules="required"
                >
                  <label for="associes_acheteur">Associés/Acheteur</label>
                  <b-form-select
                    id="associes_acheteur"
                    v-model="addManifesteForm.associe"
                    :options="associes_acheteurList"
                    value-field="id"
                    text-field="name"
                    @change="checkAssocie"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{
                    errors ? errors[0] : errorsAssociesAcheteur
                  }}</small>
                </validation-provider>
              </b-form-group> -->

              <b-form-group class="col pr-0">
                <validation-provider
                  #default="{ errors }"
                  name="compte"
                >
                  <label for="compte">Compte</label>
                  <b-form-select
                      id="compte"
                      v-model="addManifesteForm.compte"
                      :options="comptesList"
                      value-field="id"
                      text-field="libelle"
                      @change="selectCompte"
                  />
                </validation-provider>
              </b-form-group>
              <b-form-group class="col pr-0">
                <validation-provider
                  #default="{ errors }"
                  name="chargeur"
                >
                  <label for="chargeur">Chargeur</label>
                  <b-form-select
                      id="compte"
                      v-model="addManifesteForm.chargeur"
                      :options="chargeursList"
                      value-field="id"
                      text-field="name"
                      @change="resetSelectChargeur"
                  />
                </validation-provider>
              </b-form-group>
              <b-form-group class="col pr-0">
                <validation-provider
                  #default="{ errors }"
                  name="societe"
                  rules="required"
                >
                  <label for="societe">Societe</label>
                  <b-form-input
                    disabled
                    id="societe"
                    v-model="addManifesteForm.societe"
                    :state="errors.length > 0 ? false : null"
                  >
                  </b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small class="text-danger">{{ errorsSociete }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group class="col pr-0">
                <label for="num_voyage">Numéro Voyage</label>
                <b-form-input
                    id="num_voyage"
                    type="text"
                    placeholder="A78596"
                    v-model="addManifesteForm.num_voyage"/>
              </b-form-group>

              <b-form-group class="col pr-0">
                <validation-provider
                    #default="{ errors }"
                    name="navire"
                >
                  <label for="navire">Navire</label>
                  <combo-box-component
                      id="navire"
                      :dataSource="naviresList"
                      v-model="addManifesteForm.navire"
                      @click="resetInputNavire"
                  >
                  </combo-box-component>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="row">
              <div class="demo-inline-spacing">
                <b-form-group class="col pr-0">
                  <b-form-checkbox v-model="checkbox_association">
                    Transport payé par l'association
                  </b-form-checkbox>
                </b-form-group>
              </div>
            </div>
          </b-card>

          <b-card class="mt-1 block-text" title="">
            <div class="border-bottom pb-1 mb-2">
              <span class="h4">VEHICULE DU MANIFESTES</span>
            </div>

            <div>
              <div
                v-for="(item, index) in vehicules"
                :key="item.id"
                class="row flex-lg-nowrap overflow-auto"
                :id="item.id"
              >
                <b-form-group class="col-xl-2 col-xxl-2 col-lg-2 col-md-2 col-sm-12 pr-0">
                  <validation-provider
                      #default="{ errors }"
                      name="marque"
                      rules="required"
                  >
                    <label for="marque">Marque</label>
                    <combo-box-component
                      id="marque"
                      required
                      :dataSource="marquesList"
                      v-model="item.marque"
                      :state="errors.length > 0 ? false : null"
                  >
                  </combo-box-component>
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="col-xl-1 col-xxl-1 col-lg-1  col-md-2  col-sm-12 pr-0">
                  <validation-provider
                    #default="{ errors }"
                    name="numero_chassis"
                    rules="required"
                  >
                    <label for="numero_chassis">Chassis</label>
                    <b-form-input
                      id="numero_chassis"
                      type="text"
                      placeholder="Chassis"
                      v-model="item.numero_chassis"
                      @input="checkChassis(index)"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{
                      checkNumeroChassis[index] ? checkNumeroChassis[index] : ""
                    }}</small>
                    <small class="text-danger">{{
                      errors[0]
                        ? errors[0]
                        : item.errorNumero_chassis
                        ? item.errorNumero_chassis
                        : ""
                    }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="col-xl-1 col-xxl-1 col-lg-2 col-md-2  col-sm-12 pr-0">
                  <validation-provider
                      #default="{ errors }"
                      name="couleur"
                      rules="required"
                  >
                    <label for="couleur">Couleur</label>
                    <combo-box-component
                      id="couleur"
                      :dataSource="couleursList"
                      v-model="item.couleur"
                      :state="errors.length > 0 ? false : null"
                  >
                  </combo-box-component>
                  <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group class=" col-xl-1 col-xxl-1 col-lg-1 col-md-2  col-sm-12 ml-md-1">
                  <validation-provider
                    #default="{ errors }"
                    name="annee"
                    rules="required"
                  >
                    <label for="annee">Année</label>
                    <b-form-input
                      id="annee"
                      type="text"
                      placeholder="Année"
                      v-model="item.annee"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                
                <b-form-group class="col-xl-1 col-xxl-1 col-lg-1  col-md-2  col-sm-12 mr-lg-2 mr-xl-2">
                  <validation-provider
                    #default="{ errors }"
                    name="prix_achat"
                    rules="required"
                  >
                    <label for="prix_achat">Achat</label>
                    <cleave
                      id="prix_achat"
                      v-model="item.prix_achat"
                      class="form-control"
                      style="width: 7rem;"
                      :raw="false"
                      placeholder="0"
                      :options="numberFormat"
                      @input="prixRevientItem(item)"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="col-xl-1 col-xxl-1 col-lg-1  col-md-2  col-sm-12 mr-lg-2 mr-xl-2">
                  <validation-provider
                    #default="{ errors }"
                    name="prix_transp"
                    rules="required"
                  >
                    <label for="prix_transp">Transp</label>
                    <cleave
                      id="prix_transp"
                      v-model="item.prix_transp"
                      class="form-control"
                      :raw="false"
                      placeholder="0"
                      style="width: 7rem;"
                      :options="numberFormat"
                      @input="prixRevientItem(item)"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="col-xl-1 col-xxl-1 col-lg-1  col-md-2  col-sm-12 mr-lg-2 mr-xl-2">
                  <validation-provider
                    #default="{ errors }"
                    name="prix_carte_imp"
                    rules="required"
                  >
                    <label for="prix_carte_imp">Carte</label>
                    <cleave
                      id="prix_carte_imp"
                      v-model="item.prix_carte_imp"
                      class="form-control"
                      style="width: 7rem;"
                      :raw="false"
                      placeholder="0"
                      :options="numberFormat"
                      @input="prixRevientItem(item)"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="col-xl-1 col-xxl-1 col-lg-1  col-md-2  col-sm-12 mr-lg-2 mr-xl-2">
                  <validation-provider
                    #default="{ errors }"
                    name="frais"
                    rules="required"
                  >
                    <label for="frais">Frais</label>
                    <cleave
                      id="frais"
                      v-model="item.frais"
                      class="form-control"
                      :raw="false"
                      placeholder="0"
                      style="width: 7rem;"
                      :options="numberFormat"
                      @input="prixRevientItem(item)"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group class="col-xl-2 col-xxl-2 col-lg-2  col-md-2  col-sm-12">
                  <label for="prix_revient">Prix de revient</label>
                  <b-button block variant="secondary">
                    {{ $thousandSeparator(item.prix_revient)}}
                  </b-button>
                </b-form-group>

                <div class="col-1" v-if="showRemoveItem > 1">
                  <b-button
                    variant="outline-danger"
                    class="mt-0 mt-md-2"
                    @click="removeItem(index)">
                    <feather-icon icon="XIcon" />
                    <span></span>
                  </b-button>
                </div>
              </div>
            </div>

            <b-button
              size="sm"
              variant="primary"
              :disabled="disabledButton"
              @click="repeateAgain"
              class="my-1"
            >
              <b-icon-plus></b-icon-plus>
              AJOUTER UNE LIGNE
            </b-button>
          </b-card>

          <!-- submit button start -->
          <div class="BlockbButton mt-2">
            <div>
              <b-button
                type="reset"
                class="mr-2 annuler"
                variant="outline-secondary"
                @click="resetInputAll"
              >
                Annuler
              </b-button>

              <b-button
                v-if="etatButton"
                type="submit"
                variant="primary"
                @click.prevent="saveManifeste"
              >
                Enregistrer
              </b-button>

              <b-button v-else variant="primary">
                <b-icon-arrow-clockwise
                  animation="spin"
                  font-scale="1"
                ></b-icon-arrow-clockwise>
                En cours
              </b-button>
            </div>
          </div>
          <!-- submit button end -->
        </b-form>
      </validation-observer>
      <!-- form end -->
    </div>

    <b-card class="mt-5 block-text" style="width: 600px" title="">
      <div class="border-bottom pb-1">
        <span class="h4">SYNTHÈSE DES PRIX</span>
      </div>

      <b-table-simple responsive>
        <b-thead>
          <b-tr>
            <b-th>ELEMENT</b-th>
            <b-th>VALEUR</b-th>
            <b-th>DEVISE</b-th>
            <b-th>TAUX</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>PRIX D'ACHAT</b-td>
            <b-td
            >{{ $thousandSeparator(totalPrixAchat) }}
              {{ deviseAchat.abbreviation }}</b-td
            >
            <b-td
            ><b-form-select
                id="devise_achat"
                v-model="devisesManif.dev_achat"
                :options="devises"
                value-field="id"
                text-field="name"
                class="col mr-1"
                @change="changeDeviseAchat()"
            /></b-td>
            <b-td>
              <b-form-input
                  id="devise_achat"
                  v-model="deviseAchat.value"
                  :disabled="deviseAchat.abbreviation===devise_principale.abbreviation"
                  type="text"
                  @input="updateTaux()"
                  class="col mr-1"/>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>PRIX DE TRANSPORT</b-td>
            <b-td
            >{{ $thousandSeparator(totalPrixTransport) }}
              {{ deviseTransport.abbreviation }}</b-td
            >
            <b-td
            ><b-form-select
                id="devise_carte"
                v-model="devisesManif.dev_transport"
                :options="devises"
                value-field="id"
                text-field="name"
                class="col mr-1"
                @change="changeDeviseTransport()"
            /></b-td>
            <b-td>
              <b-form-input
                  :disabled="deviseTransport.abbreviation===devise_principale.abbreviation"
                  id="devise_transport"
                  v-model="deviseTransport.value"
                  @input="updateTaux()"
                  type="text"
                  class="col mr-1"/>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>PRIX CARTE IMPORTATEUR</b-td>
            <b-td
            >{{ $thousandSeparator(totalPrixCarteImp) }}
              {{ deviseCarte.abbreviation }}</b-td
            >
            <b-td
            ><b-form-select
                id="devise_carte"
                v-model="devisesManif.dev_carte_imp"
                :options="devises"
                value-field="id"
                text-field="name"
                class="col mr-1"
                @change="changeDeviseCarte()"
            /></b-td>
            <b-td>
              <b-form-input
                  id="devise_carte"
                  v-model="deviseCarte.value"
                  :disabled="deviseCarte.abbreviation===devise_principale.abbreviation"
                  type="text"
                  @input="updateTaux()"
                  class="col mr-1"/>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>FRAIS</b-td>
            <b-td
            >{{ $thousandSeparator(totalFrais) }}
              {{ deviseFrais.abbreviation }}</b-td
            >
            <b-td
            ><b-form-select
                id="devise_frais"
                v-model="devisesManif.dev_frais"
                :options="devises"
                value-field="id"
                text-field="name"
                class="col mr-1"
                @change="changeDeviseFrais()"
            /></b-td>
            <b-td>
              <b-form-input
                  id="devise_frais"
                  v-model="deviseFrais.value"
                  :disabled="deviseFrais.abbreviation===devise_principale.abbreviation"
                  type="text"
                  @input="updateTaux()"
                  class="col mr-1"/>
            </b-td>
          </b-tr>
          <b-tr>
            <b-td>PRIX DE REVIENT</b-td>
            <b-td
            >{{ $thousandSeparator(calculerPrixRevient) }}
              {{ devise_principale.abbreviation }}</b-td
            >
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import VueSweetalert2 from "vue-sweetalert2";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";
import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import {
  BRow,
  BCol,
  BTooltip,
  BCard,
  BTable,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BForm,
  BAlert,
  BModal,
  BButton,
  BIconPlus,
  BCardText,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BInputGroup,
  BFormCheckbox,
  BFormDatepicker,
  BIconCircleFill,
  BIconBuilding,
  BFormDatalist,
  BIconLayers,
  BIconOption,
  BIconFile,
  BIconCheckCircleFill,
  BIconPrinterFill,
  BIconArrowClockwise,
  BIconCalendarEvent,
  BInputGroupAppend,
  BFormSelectOption,
  BIconFileEarmarkArrowDownFill,
} from "bootstrap-vue";
import { ModelListSelect } from "vue-search-select";
import {
  ComboBoxComponent,
  ComboBoxPlugin,
} from "@syncfusion/ej2-vue-dropdowns";

export default {
  components: {
    Cleave,
    VueSweetalert2,
    VueGoodTable,
    BCardCode,
    BTooltip,
    BPagination,
    BFormSelect,
    ModelListSelect,
    BFormCheckbox,
    EmptyList,
    BIconPlus,
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BInputGroup,
    BFormDatalist,
    BAlert,
    BInputGroupAppend,
    BFormGroup,
    vSelect,
    BCardText,
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BFormDatepicker,
    BIconCircleFill,
    BIconArrowClockwise,
    BIconBuilding,
    ComboBoxPlugin,
    ComboBoxComponent,
    BIconLayers,
    BIconOption,
    BIconFile,
    BIconPrinterFill,
    BIconCheckCircleFill,
    BIconCalendarEvent,
    BFormSelectOption,
    BIconFileEarmarkArrowDownFill,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      disabledButton: false,
      numberFormat: {
        numeral: true,
        delimiter: " ",
        numeralThousandsGroupStyle: "thousand",
      },
      checkNumeroChassis: [],
      associationsList: [],
      comptesList: [],
      societe: [],
      chargeursList: [],
      chargeursRest: null,
      naviresList: [],
      marquesList: [],
      fieldsCombo: { text: "name", value: "name" },
      couleursList: [],
      associes_acheteurList: [],
      associeacheteur: {},
      compte: {},
      associe: {},
      //boite_vitesses: ["Manuel", "Automatique"],
      //cylindres: ["V4", "V6", "V8", "V12"],
      acheteur: {},
      association: {},
      deviseCartePrincipale: "",
      deviseCartesList: [],
      devisesManif: [],
      chargeurs:{},
      devise_principale: "",
      devises: [],
      clients: [],
      isStockInitial: true,
      checkbox_association: false,
      dialogisStockInitial: false,
      showManifestStockInitial: false,
      etatButton: true,
      nextTodoId: 2,
      newdevise_achat: 0,
      newdevise_transp: 0,
      newdevise_frais: 0,
      newdevise_import: 0,
      showRemoveItem: 1,
      vehicules: [
        {
          id: 1,
          marque: "",
          numero_chassis: "",
          couleur: "",
          annee: "",
          prix_achat: 0,
          prix_transp: 0,
          prix_carte_imp: 0,
          frais: 0,
          errorNumero_chassis: "",
          boite: "",
          cylindre: "",
        },
      ],
      addManifesteForm: {
        association: "",
        associe: "",
        compte: "",
        num_manifeste: "",
        num_voyage: "",
        societe: "",
        chargeur: "",
        navire: "",
        vehicules: [],
        prix_revient: 0,
        nbr_vehicule: "",
        date_arrivee: new Date().toISOString().substr(0, 10),
      },
      errorsAssociation: null,
      errorsAssociesAcheteur: null,
      errorsCompte: null,
      errorsChargeur: null,
      errorsSociete: null,
      errorsNavire: null,
      commission_acheteur: 0,

    };
  },
  mounted() {
    //this.initTrHeight()

    this.getAssociationsList();
    this.getSociete();
    this.getNaviresList();
    this.getClients();
    this.getMarquesList();
    this.getCouleursList();
    this.getDevises();
  },
  created() {
    //window.addEventListener('resize', this.initTrHeight)
    this.getManifDevises();
  },
  destroyed() {
    //window.removeEventListener('resize', this.initTrHeight)
  },

  computed: {
    deviseAchat() {
      return this.devisesManif.devise_achat
        ? this.devisesManif.devise_achat
        : { value: 0 };
    },
    deviseTransport() {
      return this.devisesManif.devise_transport
        ? this.devisesManif.devise_transport
        : { value: 0 };
    },
    deviseCarte() {
      return this.devisesManif.devise_carte_imp
        ? this.devisesManif.devise_carte_imp
        : { value: 0 };
    },
    deviseFrais() {
      return this.devisesManif.devise_frais
        ? this.devisesManif.devise_frais
        : { value: 0 };
    },
    // CALCULATE EACH TOTAL AMOUNT
    totalPrixAchat() {
      let montant = 0;
      if (this.vehicules.length > 0) {
        this.vehicules.forEach((vehicule) => {
          montant += vehicule.prix_achat
            ? this.$thousandFormater(vehicule.prix_achat)
            : 0;
        });
      }
      return montant;
    },
    totalPrixTransport() {
      let montant = 0;
      if (this.vehicules.length > 0) {
        this.vehicules.forEach((vehicule) => {
          montant += vehicule.prix_transp
            ? this.$thousandFormater(vehicule.prix_transp)
            : 0;
        });
      }
      return montant;
    },

    totalPrixCarteImp() {
      let montant = 0;

      if (this.vehicules.length > 0) {
        this.vehicules.forEach((vehicule) => {
          montant += vehicule.prix_carte_imp
            ? this.$thousandFormater(vehicule.prix_carte_imp)
            : 0;
        });
      }
      return montant;
    },
    totalFrais() {
      let montant = 0;
      if (this.vehicules.length > 0) {
        this.vehicules.forEach((vehicule) => {
          montant += vehicule.frais
            ? this.$thousandFormater(vehicule.frais)
            : 0;
        });
      }
      return montant;
    },

    calculerPrixRevient() {
      return (
        this.$thousandFormater(this.totalPrixAchat) * this.deviseAchat.value +
        this.$thousandFormater(this.totalPrixTransport) *
          this.deviseTransport.value +
        this.$thousandFormater(this.totalPrixCarteImp) *
          this.deviseCarte.value +
        this.$thousandFormater(this.totalFrais) * this.deviseFrais.value
      );
    },
  },

  methods: {
    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    repeateAgain() {
      this.showRemoveItem = this.showRemoveItem + 1;

      this.vehicules.push({
        id: (this.nextTodoId += this.nextTodoId),
        prix_achat: 0,
        prix_transp: 0,
        prix_carte_imp: 0,
        frais: 0,
        boite: "",
        cylindre: "",
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },

    removeItem(index) {
      this.showRemoveItem = this.showRemoveItem - 1;
      this.vehicules.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },

    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },

    checkChassis(index) {
      if (this.vehicules[index].errorNumero_chassis) {
        this.vehicules[index].errorNumero_chassis = "";
      }

      let enteredChassis = this.vehicules[index].numero_chassis;

      for (let i = 0; i < this.vehicules.length; i++) {
        const item = this.vehicules[i];

        if (i !== index && item.numero_chassis == enteredChassis) {
          this.checkNumeroChassis[index] =
            "Ce numéro est déjà choisi au niveau de la ligne " + (i + 1);
          this.disabledButton = true;

          i = this.vehicules.length;
        } else {
          this.disabledButton = false;

          if (this.checkNumeroChassis.length) {
            this.checkNumeroChassis = [];
          }
        }
      }
    },

    getManifDevises() {
      this.$http.get("/devises-manifeste").then((response) => {
        this.devisesManif = response.data.data[0];
      });
    },

    getAssociationsList() {
      this.$http.get("/associations").then((res) => {
        this.associationsList = res.data.data;
        if (this.associationsList != null && this.associationsList.length===1) {
          this.addManifesteForm.association = this.associationsList[0].id;
          this.$http.get("/comptes").then((response) => {
            this.comptesList = response.data.data.filter((compte) => {
              if (this.compte != null && compte.author_association === this.addManifesteForm.association )
              {
                return this.addManifesteForm.compte=compte.id;
              }
            });
          });
          this.$http.get("/chargeurs").then((res) => {
            this.chargeursList= [];
            res.data.data.filter((chargeur) => {
              chargeur.associations.filter((asso)=>{
                if (asso.id === this.addManifesteForm.association)
                {
                  this.chargeursList.push(chargeur);
                  return this.addManifesteForm.chargeur=chargeur.id;
                }
              })

            });
          });
        }

      });
    },

    getSociete() {
      this.$http
        .get("/societes")
        .then((res) => {
          this.societe = res.data.data[0];

          if (this.societe != null) {
            this.addManifesteForm.societe = this.societe.name;
          }
        })
        .catch((e) => {
          console.log("erreur societe:", e.response.data.message);
        });
    },

    getNaviresList() {
      this.$http
        .get("/navires")
        .then((res) => {
          this.naviresList = res.data.data.reverse();
          this.naviresList.forEach((element) => {
            element.text = element.name;
          });
        })
        .catch((e) => {
          console.log("erreur naviresList:", e.response.data.message);
        });
    },

    getClients() {
      this.$http.get("/clients").then((res) => {
        this.clients = res.data.data;
      });
    },

    getMarquesList() {
      this.$http.get("/marques").then((res) => {
        this.marquesList = res.data.data.reverse();
        this.marquesList.forEach((element) => {
          element.text = element.name;
        });
      });
    },

    getCouleursList() {
      this.$http.get("/couleurs").then((res) => {
        this.couleursList = res.data.data.reverse();

        this.couleursList.forEach((element) => {
          element.text = element.name;
        });
      });
    },

    formatAmounts(vehicules) {
      vehicules = JSON.parse(JSON.stringify(vehicules));
      vehicules.forEach((element) => {
        element.prix_achat = this.$thousandFormater(element.prix_achat);
        element.prix_carte_imp = this.$thousandFormater(element.prix_carte_imp);
        element.prix_transp = this.$thousandFormater(element.prix_transp);
        if (element.frais)
          element.frais = this.$thousandFormater(element.frais);
        if (element.rap) element.rap = this.$thousandFormater(element.rap);
        if (element.montant_vente)
          element.montant_vente = this.$thousandFormater(element.montant_vente);
      });
      return vehicules;
    },
    // add manifeste start
    saveManifeste() {
      this.$refs.addForm.validate().then((success) => {
        if (!this.checkNumeroChassis.length) {
          let checkError = false;
          if (this.vehicules.length == 0) {
            this.showToast(
              "Veuillez ajouter au moins un véhicule au manifeste",
              "danger"
            );
          }

          for (let i = 0; i < this.vehicules.length; i++) {
            if (this.vehicules[i].errorNumero_chassis) {
              checkError = true;
              i = this.vehicules.length;
            }
          }

          if (!checkError) {
            //SET DEVISE DE VENTE
            this.devisesManif.devise_vente = this.devises.find(
              ({ id }) => id === this.devisesManif.dev_vente
            );

            let vehicules = this.formatAmounts(this.vehicules);

            if(this.addManifesteForm.navire) 
            {
                this.addManifesteForm.navire = 
                this.addManifesteForm.navire.toUpperCase();
            }
            
            this.addManifesteForm.vehicules = vehicules;
            this.addManifesteForm.enregistrement_stock_initial = false;
            this.addManifesteForm.checkbox_association =
              this.checkbox_association;
            this.addManifesteForm.devisesManifeste = this.devisesManif;
            this.addManifesteForm.prix_revient = this.calculerPrixRevient;
            this.addManifesteForm.taux_achat=this.devisesManif.devise_achat.value;
            this.addManifesteForm.taux_import=this.devisesManif.devise_carte_imp.value;
            this.addManifesteForm.taux_transp=this.devisesManif.devise_transport.value;
            this.addManifesteForm.taux_frais=this.devisesManif.devise_frais.value;

            if (this.compte && this.compte.author_type == "acheteur") {
              this.addManifesteForm.commission_acheteur =
                this.$thousandFormater(this.commission_acheteur);
            }
            if (success) {
              console.log(this.addManifesteForm)
              this.etatButton = false;
              this.$http
                .post("/manifestes", this.addManifesteForm)
                .then((result) => {
                  if (result.data.success) {
                    this.etatButton = true;
                    this.showToast("Manifeste ajouté avec succès", "success");
                    this.$router.go(-1);
                  } else {
                    this.etatButton = true;
                    
                    this.showToast(result.data.message, "danger");
                  }
                })
                .catch((e) => {
                  
                  this.etatButton = true;
                  var nbrVehicules = this.vehicules.length;

                  var showToast = true;

                  for (var i = 0; i < nbrVehicules; i++) {
                    if (e.response.data["vehicules." + i + ".numero_chassis"]) {
                      this.vehicules[i].errorNumero_chassis =
                        e.response.data[
                          "vehicules." + i + ".numero_chassis"
                        ][0];
                      showToast = false;
                    }
                  }
                  if (
                    !e.response.data.success &&
                    e.response.data.message &&
                    showToast
                  ) {
                    this.showToast(e.response.data.message, "danger");
                  }
                });
            }
          }
        }
      });
    },

    resetInputAll() {
      this.addManifesteForm.associe = "";
      this.errorsAssociation = null;
      this.errorsAssociesAcheteur = null;
      this.errorsCompte = null;
      this.errorsChargeur = null;
      this.errorsSociete = null;
      this.errorsNavire = null;
      this.errorsVehiculeManifeste = [];
    },

    async checkAssociation() {
      if (this.errorsAssociation) {
        this.errorsAssociation = null;
      }
      this.association = this.associationsList.find(
        (_element) => _element.id == this.addManifesteForm.association
      );
      let associes_acheteurList = this.association.acheteurs_associes;
      await this.$http.get("/comptes").then((response) => {
        this.comptesList = response.data.data.filter((compte) => {
          // console.log(compte.author_type) ;
          if (compte.author_type === "associe" && compte.author_association === this.addManifesteForm.association)
          {
            return this.addManifesteForm.compte=compte.id;
          }
        });
        associes_acheteurList.filter((element) => {
          if (element.type === "acheteur") {
            this.comptesList.push(element.compte[0]);
          }
        });
      });
      await this.$http.get("/chargeurs").then((res) => {
        this.chargeursList= [];
        res.data.data.filter((chargeur) => {
          chargeur.associations.filter((asso)=>{
           if (asso.id === this.addManifesteForm.association)
           {
             this.chargeursList.push(chargeur);
             return this.addManifesteForm.chargeur=chargeur.id;
           }
         })

        });
      });

    },

    selectCompte() {
      if (this.addManifesteForm.compte) {
        this.compte = this.comptesList.filter((element) => {
          console.log(element)
          return element.id == this.addManifesteForm.compte;
        })[0];

        this.devisesManif.dev_achat = this.devises.find(
          ({ name }) => name === this.compte.devise
        ).id;
        this.devisesManif.dev_transport = this.devises.find(
          ({ name }) => name === this.compte.devise
        ).id;

        this.changeDeviseAchat();
        this.changeDeviseTransport();
        if (this.errorsCompte) {
          this.errorsCompte = null;
        }
      }
    },
    // changeDeviseAchat() {
    //   this.devisesManif.devise_achat = this.devises.find(
    //     ({ name }) => name === this.compte.devise
    //   );
    //   this.devisesManif.dev_achat = this.devisesManif.devise_achat.id;
    // },

    changeDeviseAchat() {
      this.devisesManif.devise_achat = this.devises.find(
        ({ id }) => id === this.devisesManif.dev_achat
      );

      // let num = parse(element.p_achat ? element.p_achat.toString() : '0', this.options)
      // console.log(num*this.deviseAchat.value)
      this.vehicules.forEach((element) => {
        this.prixRevientItem(element);
      });
    },

    changeDeviseCarte() {
      this.devisesManif.devise_carte_imp = this.devises.find(
        ({ id }) => id === this.devisesManif.dev_carte_imp
      );

      this.vehicules.forEach((element) => {
        this.prixRevientItem(element);
      });
    },

    changeDeviseTransport() {
      this.devisesManif.devise_transport = this.devises.find(
        ({ id }) => id === this.devisesManif.dev_transport
      );

      this.vehicules.forEach((element) => {
        this.prixRevientItem(element);
      });
    },

    changeDeviseFrais() {
      this.devisesManif.devise_frais = this.devises.find(
        ({ id }) => id === this.devisesManif.dev_frais
      );
console.log( this.devisesManif.devise_frais)
      this.vehicules.forEach((element) => {
        this.prixRevientItem(element);
      });
    },

    resetSelectChargeur() {
      if (this.errorsChargeur) {
        this.errorsChargeur = null;
      }
    },

    resetInputNavire() {
      if (this.errorsNavire) {
        this.errorsNavire = null;
      }
    },
    updateTaux(){
      this.newdevise_achat=this.devisesManif.devise_achat.value
      this.newdevise_import=this.devisesManif.devise_carte_imp.value
      this.newdevise_transp=this.devisesManif.devise_transport.value
      this.newdevise_frais=this.devisesManif.devise_frais.value
      this.addManifesteForm.taux_achat=this.devisesManif.devise_achat.value;
      this.addManifesteForm.taux_import=this.devisesManif.devise_carte_imp.value;
      this.addManifesteForm.taux_transp=this.devisesManif.devise_transport.value;
      this.addManifesteForm.taux_frais=this.devisesManif.devise_frais.value;
      this.addManifesteForm.vehicules.taux_achat=this.devisesManif.devise_achat.value;
      this.addManifesteForm.vehicules.taux_import=this.devisesManif.devise_carte_imp.value;
      this.addManifesteForm.vehicules.taux_transp=this.devisesManif.devise_transport.value;
      this.addManifesteForm.vehicules.taux_frais=this.devisesManif.devise_frais.value;
      this.vehicules.forEach((element) => {
        this.prixRevientItem(element);
      });
    },
    prixRevientItem(item) {
      if (this.newdevise_achat !==0 && this.newdevise_import !==0 &&   this.newdevise_transp !==0 && this.newdevise_frais !==0){
        if(this.devisesManif.dev_achat){
          return (item.prix_revient =
              this.$thousandFormater(item.prix_achat) * this.newdevise_achat +
              this.$thousandFormater(item.prix_carte_imp) * this.newdevise_import +
              this.$thousandFormater(item.prix_transp) * this.newdevise_transp +
              this.$thousandFormater(item.frais) * this.newdevise_frais);
        }
      }else{
        if(this.devisesManif.dev_achat){
          return (item.prix_revient =
              this.$thousandFormater(item.prix_achat) * this.deviseAchat.value +
              this.$thousandFormater(item.prix_carte_imp) * this.deviseCarte.value +
              this.$thousandFormater(item.prix_transp) * this.deviseTransport.value +
              this.$thousandFormater(item.frais) * this.deviseFrais.value);
        }
      }

    },

    getDevises() {
      this.$http
        .get("/devises")
        .then((response) => {
          this.devises = response.data.data;
          this.devise_principale = this.devises.find(
            ({ devise_principale }) => devise_principale === 1
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "@core/scss/vue/libs/vue-select.scss";
@import "../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
.e-input-group {
  border-color: rgb(0 0 0 / 15%) !important;
  border-width: 1px !important;
  border-radius: 5px !important;
  color: rgb(127 125 139) !important;
  line-height: 2.1 !important;
  padding-left: 15px !important;
}
span.e-input-group.e-control-wrapper.e-ddl:has(> select[name=couleur]) {
  width: 115% !important;
}

.e-input-group::before,
.e-input-group::after {
  background: #e3165b00 !important;
}

.block-text .card-body {
  padding-bottom: 0px;
}

.repeater-form {
  overflow: hidden;
  transition: 5s height;
}
@media (min-width: 768px) { 
  #annee {
  width: 5rem;
}
 }

</style>